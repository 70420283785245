/**
 * Slider Cubes
 *
 * @package Schibli;
 * @author  Valentin Zmaranda
 * @license GPL-2.0+
 */

const cube = (theCube) => {
	const cubesWrapper = theCube.querySelectorAll('.square-header');
	if (cubesWrapper.length === 0) {
		return false;
	}

	const isSlider = !theCube.classList.contains('featured-image');
	let squareSize = theCube.offsetHeight / 4;
	// eslint-disable-next-line camelcase
	let squareCountX = 17;
	// eslint-disable-next-line camelcase,no-unused-vars
	let yVersatz = 0;
	// eslint-disable-next-line camelcase,no-unused-vars
	let xVersatz = 0;
	// eslint-disable-next-line camelcase
	let xCount = 1;
	// eslint-disable-next-line camelcase
	let yCount = 1;
	// eslint-disable-next-line camelcase
	let animTypeNumber = 1;
	// eslint-disable-next-line camelcase
	let squareWhite = 0;
	// eslint-disable-next-line camelcase

	let patterns = [
		[2, 4, 6, 9, 12, 13, 16, 17, 19, 21, 26, 28, 31],
		[2, 4, 9, 10, 11, 13, 15, 17, 20, 21, 26, 30, 32],
		[2, 3, 7, 9, 11, 12, 14, 16, 17, 18, 21, 22, 26, 28],
		[3, 4, 7, 10, 13, 14, 17, 19, 20, 22, 24, 26, 27],
		[2, 3, 6, 9, 10, 13, 16, 17, 19, 20, 22, 26, 28],
	];

	if (isSlider) {
		squareSize = theCube.offsetHeight / 5;
		squareCountX = 14;
		patterns = [
			[1, 4, 16, 29, 31, 48, 58, 60],
			[2, 4, 15, 46, 31, 34, 43, 59],
		];
	}
	// console.log('isSlider', isSlider);
	const pattern = patterns[Math.floor(Math.random() * patterns.length)];
	if (isSlider) {
		for (let i = 1; i <= squareCountX * 5; i++) {
			animTypeNumber = Math.floor(Math.random() * 5 + 1);
			// const square_count = xCount + (yCount - 1) * 14;
			if (xCount <= 7) {
				if (pattern.includes(xCount + (yCount - 1) * 14)) {
					squareWhite = 1;
				} else {
					squareWhite = 0;
				}
			} else {
				squareWhite = 0;
			}

			// if (squareWhite == 0) {
			// 	document.getElementById('square_header').innerHTML +=
			// 		'<div class="square square_anim' +
			// 		animTypeNumber +
			// 		'" >&nbsp;</div>';
			// } else {
			// 	document.getElementById('square_header').innerHTML +=
			// 		'<div class="square square_anim' +
			// 		animTypeNumber +
			// 		'"  style="background-color:#FFF;">&nbsp;</div>';
			// }
			const width = `width:${squareSize}px`,
				height = `height:${squareSize}px`,
				boxClassName = `square square-slide square_anim${animTypeNumber}${
					squareWhite !== 0 ? ' square-white' : ''
				}`,
				innerBox = `<div class="${boxClassName}" style="${width}; ${height}">&nbsp;</div>`;

			theCube.querySelector('.square-header').innerHTML += innerBox;

			xVersatz -= squareSize;
			if (xCount === squareCountX) {
				xCount = 1;
				xVersatz = 0;
				yVersatz -= squareSize;
				yCount += 1;
			} else {
				xCount += 1;
			}
		}
	} else {
		// eslint-disable-next-line camelcase
		for (let i = 1; i <= squareCountX * 4; i++) {
			animTypeNumber = Math.floor(Math.random() * 4 + 1);

			if (xCount <= 8) {
				if (pattern.includes(xCount + (yCount - 1) * 8)) {
					squareWhite = 1;
				} else {
					squareWhite = 0;
				}
			} else {
				squareWhite = 0;
			}
			const width = `width:${squareSize}px`,
				height = `height:${squareSize}px`,
				boxClassName = `square square-slide square_anim${animTypeNumber}${
					squareWhite !== 0 ? ' square-white' : ''
				}`,
				innerBox = `<div class="${boxClassName}" style="${width}; ${height}">&nbsp;</div>`;

			theCube.querySelector('.square-header').innerHTML += innerBox;
			// if (squareWhite == 0) {
			// 	document.getElementById('square_header').innerHTML +=
			// 		'<div class="square square_anim' +
			// 		animTypeNumber +
			// 		'" >&nbsp;</div>';
			// } else {
			// 	document.getElementById('square_header').innerHTML +=
			// 		'<div class="square square_anim' +
			// 		animTypeNumber +
			// 		'"  style="background-color:#FFF;">&nbsp;</div>';
			// }

			xVersatz -= squareSize;
			if (xCount === squareCountX) {
				xCount = 1;
				xVersatz = 0;
				yVersatz -= squareSize;
				yCount += 1;
			} else {
				xCount += 1;
			}
		}
	}
};

export default cube;
